export const color = {
    darkBlue: '#242E61',
    white: '#FFFFFF',
    black: '#000000',
    white2: '#F4F5F6',
    darkRed: '#A11128',
    red: '#CD1823',
    grey: '#6C6C6C',
    grey2: '#DDDEE1',
    grey3: '#707070',
    lightGrey: '#676767',
    lightGrey2: '#EEEEEE'
}