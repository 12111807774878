import { Grid, styled } from '@mui/material';
import React from 'react';
import { color } from '../../../helpers/colors';
import { TitleSection } from '../../common/title/TitleSection';
import trazado from '../../../img/trazado.svg';
import graphic from '../../../img/graphic.svg';

const P = styled('p')({
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: color.black
});

export const AboutUsPage = () => {
    return (
        <Grid container>
            <Grid item container justifyContent='center' style={{ marginTop: 40 }} xs={12}>
                <TitleSection>Nosotros</TitleSection>
            </Grid>

            <Grid item xs={12} >
                <div style={{ borderBottom: `1px solid ${color.darkBlue}`, width: '54%', marginBottom: 40, marginTop: 15 }} />
            </Grid>

            <Grid item xs={2} md={3} />

            <Grid item xs={8} md={6}>
                <P>Somos IRAMAC distribuidora de Repuestos, dedicado a la comercialización de autopartes contamos con todas las marcas de vehículos livianos. Le bríndanos la mejor opción de compra para el mantenimiento de su vehículo, así garantizamos su comodidad.</P>
            </Grid>

            <Grid item xs={2} md={3} />

            <Grid item container justifyContent='center' style={{ marginTop: 40, marginBottom: 15 }} xs={12}>
                <TitleSection>Misión</TitleSection>
            </Grid>

            <Grid item xs={2} md={3} container justifyContent={'flex-end'} sx={{ pr: 2 }}>
                <img src={trazado} alt='icon' style={{ maxWidth: 90 }}></img>
            </Grid>

            <Grid item xs={8} md={6}>
                <P>Proveer a nuestros clientes, una amplia variedad de repuestos para automóviles de alta calidad y un servicio de excelencia, capacitando constantemente a nuestro personal y así brindar una buena asesoría y satisfacer las necesidades de nuestros clientes.</P>
            </Grid>

            <Grid item xs={2} md={3} />

            <Grid item container justifyContent='center' style={{ marginTop: 40, marginBottom: 15 }} xs={12}>
                <TitleSection>Visión</TitleSection>
            </Grid>

            <Grid item xs={2} md={3} container justifyContent={'flex-end'} sx={{ pr: 2 }}>
                <img src={graphic} alt='icon' style={{ maxWidth: 90 }}></img>
            </Grid>

            <Grid item xs={8} md={6}>
                <P>Ser una empresa Líder en la comercialización de repuestos para vehículos, tener altos índices de calidad y servicio, estar a la vanguardia de la tecnología y el conocimiento técnico para satisfacer las necesidades y expectativas de nuestros clientes y así contribuir al desarrollo económico de nuestro país y por ende el bienestar de nuestros colaboradores.</P>
            </Grid>

            <Grid item xs={2} md={3} />

        </Grid>
    );
};
