import { Box, Button, Grid, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ModalForm } from '../../common/modal/ModalForm'
import { color } from '../../../helpers/colors';
import { ReadMore } from '../../common/readMore/ReadMore';
import styled from '@emotion/styled';
import { moneyLocale } from '../../../helpers/format';
import { addProductToCart } from '../../../actions/cart';
import { useDispatch } from 'react-redux';
import { ProductIMG } from '../../common/image/IMG';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const AddCartButton = styled(Button)({
  fontFamily: 'Montserrat',
  fontSize: '12px',
  textTransform: 'none',
  backgroundColor: color.red,
  color: color.white,
  fontWeight: 500,
  borderRadius: 0,
  '&:hover': {
    backgroundColor: color.darkRed
  }
});

export const ProductDetail = ({ open, product, isLogued, setOpen }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [image, setImage] = useState(0);

  const handleNextImage = () => {
    setImage(image + 1);
  }

  const handlePrevImage = () => {
    setImage(image - 1);
  }

  useEffect(() => {
    setImage(0);
  }, [product])

  const handleChangeQuantity = ({ target: { value } }) => {
    setQuantity(value);
  }

  return (
    <ModalForm sx={{ width: { sm: '80%', md: '80%', lg: '65%', xs: '95%' } }} open={open} setOpen={setOpen}>
      <Grid container >
        <Grid item xs={12} container alignItems={'center'}>
          <Grid item xs={7} sm={9} sx={{ position: 'relative' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                boxShadow: '0px 5px 6px lightgray',
                position: 'relative'
              }}
              elevation={2}>

              <ProductIMG style={{ maxHeight: 700, width: 'auto', maxWidth: '100%', objectFit: 'contain' }} src={product.Imagenes[image]?.UrlImagen} />

              {
                (image > 0) && (
                  <IconButton
                    onClick={handlePrevImage}
                    sx={{ position: 'absolute', color: color.darkBlue, top: '50%', left: -8, transform: 'translateY(-50%)' }}>
                    <ArrowBackIosIcon sx={{ fontSize: 35 }} />
                  </IconButton>
                )
              }

              {
                (image < product?.Imagenes?.length - 1) && (
                  <IconButton
                    onClick={handleNextImage}
                    sx={{ position: 'absolute', color: color.darkBlue, top: '50%', right: -8, transform: 'translateY(-50%)' }}>
                    <ArrowForwardIosIcon sx={{ fontSize: 35 }} />
                  </IconButton>
                )
              }
            </Box>

          </Grid>

          <Grid item xs={5} sm={3}>
            <Box component="div" sx={{ p: '5px 10px 5px 15px' }}
              style={{ backgroundColor: color.darkRed, color: color.white, textAlign: 'center' }}>
              <span
                style={{ fontFamily: 'Montserrat', fontWeight: 300, fontSize: 17 }}>
                {product.Nombre}
              </span>
            </Box>

            <div style={{ margin: '30px 0px 0px 20px', fontFamily: 'Montserrat', fontWeight: 200 }}>
              <ReadMore>
                {product.DescripcionLarga}
              </ReadMore>
            </div>

            {
              isLogued && (
                <Grid sx={{ ml: 2 }} container>

                  <Grid item xs={12}>
                    <span style={{
                      color: color.darkBlue,
                      fontSize: 20,
                      fontWeight: 'bold'
                    }}>
                      {moneyLocale.format(product.PrecioVenta)}
                    </span>
                  </Grid>
                  <Grid item container xs={12} gap={1} alignItems='center'>
                    <input
                      type="number"
                      min={1}
                      onChange={handleChangeQuantity}
                      value={quantity}
                      style={{
                        width: '50px',
                        height: '30px',
                        textAlign: 'center',
                        fontFamily: 'Montserrat',
                      }} />
                    <AddCartButton onClick={() => dispatch(addProductToCart({ ...product, quantity: quantity < 1 ? 1 : quantity }))} disableElevation variant="contained">Agregar</AddCartButton>
                  </Grid>
                </Grid>
              )
            }
          </Grid>
        </Grid>
      </Grid>
    </ModalForm>
  )
}
