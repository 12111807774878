import React from 'react'
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import CarouselMulti from "react-multi-carousel";
import { TitleSection } from '../../common/title/TitleSection';
import { color } from '../../../helpers/colors';
import hd from '../../../img/brands/hd.png'
import iramac from '../../../img/brands/iramac.png'
import IRM from '../../../img/brands/IRM.png'
import shortid from 'shortid';

const images = [
    hd,
    iramac,
    IRM
];

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1201 },
        items: 4
    },
    desktop: {
        breakpoint: { max: 1200, min: 901 },
        items: 3,
        paritialVisibilityGutter: 60
    },
    tablet: {
        breakpoint: { max: 900, min: 601 },
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: { max: 600, min: 0 },
        items: 1,
        paritialVisibilityGutter: 30
    }
};

export const Brands = () => {
    return (
        <>
            <Grid item container justifyContent='center' xs={12}>
                <TitleSection>Marcas</TitleSection>
            </Grid>

            <Grid item xs={12} >
                <div style={{ borderBottom: `1px solid ${color.darkBlue}`, width: '54%', marginBottom: 40, marginTop: 15 }} />
            </Grid>

            <Grid item xs={12}>
                <Box component="div" sx={{ pl: { xs: 1, md: 12 }, pr: { xs: 1, md: 12 } }}>

                    <CarouselMulti
                        ssr
                        partialVisbile
                        responsive={responsive}
                    >
                        {images.slice(0, 5).map(image => {
                            return (
                                <img
                                    key={shortid.generate()}
                                    alt=''
                                    style={{ width: "150px", height: "100%" }}
                                    src={image}
                                />
                            );
                        })}
                    </CarouselMulti>
                </Box>
            </Grid>
        </>
    )
}
