import { Box, IconButton } from '@mui/material'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled } from '@mui/material/node_modules/@mui/system';
import { color } from '../helpers/colors';

const Quantity = styled('span')({
    color: color.black,
    fontSize: 17,
    fontFamily:'Source Sans Pro'
});

export const useQuantitySelector = () => {

    const [value, setValue] = useState(1);

    const handleAdd = () => {
        setValue(value + 1);
    }

    const handleSubstrack = () => {
        if (value > 1) {
            setValue(value - 1);
        }
    }

    const view = () => (<Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: 10,
            borderColor: color.grey2,
            borderWidth: 1,
            borderStyle: 'solid'
        }}>
        <IconButton onClick={handleSubstrack}>
            <RemoveIcon sx={{ color: color.black, fontSize: 19 }} />
        </IconButton>

        <Quantity>{value}</Quantity>

        <IconButton onClick={handleAdd}>
            <AddIcon sx={{ color: color.black, fontSize: 19 }} />
        </IconButton>

    </Box>);



    return ({ value, QuantitySelector: view });
}
