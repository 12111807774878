import { Grid } from '@mui/material';
import React, { memo } from 'react';
import ProductCard  from './ProductCard';
import shortid from 'shortid';

export const ProductsList = memo(({ isLogued, products = [] }) => {
    
    return (
        <Grid container spacing={7}>
            {
                products.map(product => (
                    <ProductCard key={shortid.generate()} isLogued={isLogued} product={product} />
                ))
            }
        </Grid>
    );
});
