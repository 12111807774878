import { types } from "../helpers/types";

export const showSnack = (message, severity) => ({
    type: types.showSnack,
    payload: {
        message: message,
        severity: severity
    }
});

export const hideSnack = () => ({
    type: types.hideSnack,
});