import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { TitleSection } from "../title/TitleSection";
import { MaterialInput } from "../input/MaterialInput";
import { ButtonControl } from "../button/Button";
import { Select } from "../select/Select";
import ReCAPTCHA from "react-google-recaptcha";
import { postAsync } from "../../../api/apiService";
import endpoint from "../../../api/endpoint";
import { showSnack } from "../../../actions/ui";

const data = [
  {
    label: "Cedula",
    value: "Cedula",
  },
];

export const RegisterPage = ({ setLoading, dispatch, setOpen }) => {
  const validationMethods = useForm();
  const registerMethods = useForm();
  const [userData, setUserData] = useState(null);

  const onSubmitRegister = async (data) => {
    if (data.Contrasena !== data.confirmarContrasena) {
      dispatch(showSnack('Las contraseñas deben coincidir', 'warning'));
      return;
    }

    const response = await postAsync(endpoint.register, data);

    if (response.respuesta) {
      setOpen(false);
    }

    dispatch(showSnack(response.mensaje, response.respuesta ? 'success' : 'error'));
  };

  const onSubmitValidation = async (data) => {

    setLoading(true);
    const response = await postAsync(endpoint.validateDocument, data);
    setLoading(false);

    if (response.respuesta) {
      setUserData(response.dato);
      registerMethods.setValue("Id", response?.dato?.Id);
      registerMethods.setValue("Telefono", response?.dato?.Telefono);
      registerMethods.setValue("Correo", response?.dato?.Correo);
      registerMethods.setValue("Nombre", response?.dato?.Nombre);
      return;
    }
    setUserData(null);
    dispatch(showSnack(response.mensaje, 'warning'));
  };

  useEffect(() => {
    validationMethods.setValue("tipo", "Cedula");
  }, [validationMethods])


  const [token, setToken] = useState(null);

  return (
    <Box>
      {
        userData ? (
          <form onSubmit={registerMethods.handleSubmit(onSubmitRegister)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TitleSection>Datos personales</TitleSection>
              </Grid>
              <FormProvider {...registerMethods}>
                <Grid item xs={12} md={6}>
                  <MaterialInput
                    disabled
                    name="Nombre"
                    label="Nombre" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MaterialInput
                    name="Correo"
                    type="email"
                    label="Correo electrónico"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MaterialInput name="Telefono" label="Teléfono" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MaterialInput
                    name="Contrasena"
                    type="password"
                    label="Contraseña"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <MaterialInput
                    type="password"
                    name="confirmarContrasena"
                    label="Confirmar Contraseña"
                  />
                </Grid>

                <Grid item xs={12} md={6} />

                <Grid item xs={12} md={4}>
                  <ButtonControl type="submit" text="Enviar" />
                </Grid>
              </FormProvider>
            </Grid>
          </form>
        ) : (
          <form onSubmit={validationMethods.handleSubmit(onSubmitValidation)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TitleSection>Registro</TitleSection>
              </Grid>
              <FormProvider {...validationMethods}>
                <Grid item xs={12}>
                  <Select name="tipo" options={data} label="Tipo de documento" />
                </Grid>

                <Grid item xs={12}>
                  <MaterialInput type="number" name="Cedula" label="Cedula" />
                </Grid>

                <Grid item xs={12}>
                  <ReCAPTCHA
                    sitekey="6LeAsTQgAAAAAG1b-JBcy-ECLBYBth82NSX4GolK"
                    onChange={setToken}
                    onExpired={() => setToken(null)}
                  />
                </Grid>

                <Grid item xs={4}>
                  <ButtonControl disabled={token ? false : true} type="submit" text="Validar" />
                </Grid>
              </FormProvider>
            </Grid>
          </form>
        )
      }
    </Box>
  );
};
