import { styled, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useFormContext } from "react-hook-form";
import { color } from '../../../helpers/colors';

const StyledInput = styled(TextField)({
    width: '100%',
    '& label.Mui-focused': {
        color: color.darkBlue,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: color.grey,
        },
        '&.Mui-focused fieldset': {
            borderColor: color.darkBlue,
        },
    },
    '& .MuiInputBase-input': {
        fontSize: 16,
        color: color.lightGrey,
        fontFamily: 'Roboto',
        fontWeigth: 400
    }
})

export const MaterialInput = ({ name, required, ...rest }) => {
    const { register, formState: { errors } } = useFormContext();
    return (
        <Box
            component='div'
            style={{ position: required ? 'relative' : '' }}
        >
            <StyledInput
                error={errors[name] ? true : false}
                {...register(name, { required })}
                {...rest}
            />

            {
                required && (
                    <span style={{ position: 'absolute', top: 2, right: -16, color: color.darkBlue }}>*</span>
                )
            }
        </Box>
    );
};
