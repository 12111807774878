import { Button, styled } from "@mui/material";
import { color } from '../../../helpers/colors';

const StyledButton = styled(Button)(({ backgroundcolor, style }) => ({
    borderRadius: '4px',
    width: '100%',
    height: '45px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    backgroundColor: backgroundcolor,
    '&:hover': {
        backgroundColor: backgroundcolor
    },
    ...style
}));

export const ButtonControl = ({ text, backgroundColor = color.darkBlue, style, ...rest }) => {
    return (
        <StyledButton backgroundcolor={backgroundColor} style={style} disableElevation variant="contained" {...rest}>{text}</StyledButton>
    );
};
