import styled from '@emotion/styled';
import { Box, Pagination } from '@mui/material'
import React from 'react'
import { color } from '../../../helpers/colors';

const StyledPagination = styled(Pagination, {
    shouldForwardProp: (prop) => prop !== "style"
})(({ style }) => ({
    '& .MuiPagination-ul li button': {
        color: color.darkBlue,
        fontWeight: 'bold',
        fontSize: 16
    },
    ...style
}));

export const Paging = ({ changePage, page, style, totalPages }) => {
    return (
        <>
            {
                (totalPages > 1) && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                        <StyledPagination boundaryCount={2} style={style} onChange={(e, v) => changePage(v)} page={page} count={totalPages} shape="rounded" />
                    </Box>
                )
            }
        </>
    )
}
