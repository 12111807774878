const endpoints = {
    login: 'loginexterno/auth',
    orderHistory: 'orderexterno/consultar',
    orderDetail: 'orderexterno/pedidosDetalleId/:id',
    validateDocument: 'userexterno/validarCedula',
    register: 'userexterno/registro',
    products: 'productexterno',
    createOrder : 'orderexterno/registro',
    changePassword : 'userexterno/CambiarContrasena',
    profile : 'userexterno/:id',
    productRandom : 'productexterno',
    contactUs : 'enviarcorreo/enviarCorreo',
};

export default endpoints;