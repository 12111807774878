import { InputAdornment, InputBase, styled } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { color } from '../../../helpers/colors';

const StyledInput = styled(InputBase)({
    border: `1px solid ${color.grey}`,
    width: '100%',
    height:40,
    borderRadius: '4px'
})
export const Input = ({ name, icon, ...rest }) => {
    const { register } = useFormContext();
    return (
        <StyledInput
            {...register(name)}
            {...rest}
            startAdornment={
                <InputAdornment position="start">
                    {icon}
                </InputAdornment>
            }
        />
    );
};
