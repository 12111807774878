import { types } from "../helpers/types";
import { Buffer } from 'buffer';

const getCartFromStore = () => {
    const cart = localStorage.getItem('cartIramac');

    if (!cart) {
        return [];
    }

    const cartData = JSON.parse(Buffer.from(cart, 'base64'));

    return cartData;
}

export const cartReducer = (state = getCartFromStore(), action) => {
    switch (action.type) {
        case types.addProduct:
            if (state?.find(product => product.Id === action.payload.Id)) {
                return state?.map(product =>
                    product.Id === action.payload.Id ? { ...product, quantity: product.quantity + 1 }
                        : product);
            }
            return [
                ...state,
                action.payload
            ]

        case types.removeProduct:
            return [
                ...state.filter(product => product.Id !== action.payload.Id)
            ]

        case types.updateCart:
            return action.payload;

        case types.clearCart:
            return [];

        case types.updateQuantity:
            return [
                ...state.map(product => ({
                    ...product,
                    quantity: product.Id === action.payload.Id ? action.payload.quantity : product.quantity
                }))
            ]

        default:
            return state;
    }
}