import {
  Card,
  CardContent,
  Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "../../common/input/Input";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { ButtonControl } from "../../common/button/Button";
import { color } from "../../../helpers/colors";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../helpers/routes";
import { useDispatch } from "react-redux";
import { ModalForm } from "../../common/modal/ModalForm";
import { useState } from "react";
import { RegisterPage } from "../../common/register/RegisterPage";
import { useLoading } from "../../../hooks/useLoading";
import { postAsync } from "../../../api/apiService";
import endpoint from "../../../api/endpoint";
import { login } from "../../../actions/auth";
import { showSnack } from "../../../actions/ui";
import iramac from '../../../img/brands/iramac.png';

const Title = styled(Typography)({
  flexBasis: "100%",
  textAlign: "center",
  color: color.darkBlue,
  fontFamily: "Raleway",
  fontWeight: 700,
  marginTop: 4,
  marginBottom: 15,
});

export const LoginPage = () => {
  const methods = useForm();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const setLoading = useLoading();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {

    setLoading(true);
    const response = await postAsync(endpoint.login, data);
    setLoading(false);

    if (response.respuesta) {
      dispatch(login(response.usuario))
      return;
    }

    dispatch(showSnack(response.mensaje, 'warning'));
  };

  return (
    <>
      <Grid
        style={{ height: "100vh" }}
        container
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Card
          style={{ borderRadius: "91px", minWidth: "20%" }}
          sx={{
            maxWidth: { xs: "90%", lg: "30%" },
            padding: { xs: "30px", lg: "60px" },
          }}
        >
          <CardContent>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <div>
                <img
                  alt="logo"
                  src={iramac}
                  style={{
                    height: 150,
                    flexBasis: "100%",
                  }}
                />
              </div>

              <Title variant="h5">INICIAR SESIÓN</Title>

              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Input
                    type="number"
                    style={{ flexBasis: "100%", marginBottom: 15 }}
                    icon={<PersonIcon />}
                    name={"Username"}
                  />
                  <Input
                    style={{ flexBasis: "100%", marginBottom: 15 }}
                    icon={<LockIcon />}
                    type="password"
                    name={"Password"}
                  />

                  <ButtonControl
                    text={"Ingresa"}
                    type="submit"
                    style={{ marginBottom: 10 }}
                  />
                  <ButtonControl
                    onClick={() => setOpen(true)}
                    backgroundColor={color.red}
                    text={"Registrate"}
                  />
                  <span
                    style={{
                      fontSize: "15px",
                      color: "#545454",
                      fontFamily: "Raleway",
                      cursor: "pointer",
                      marginTop: 7,
                      flexBasis: "100%",
                    }}
                  >
                    ¿Olvidaste tu contraseña?
                  </span>
                </form>
              </FormProvider>
            </div>
          </CardContent>
        </Card>
        <div style={{ position: "fixed", top: 0, left: 0 }}>
          <IconButton onClick={() => navigate(routes.home)}>
            <ArrowBackIosIcon
              style={{ fontSize: 30, margin: 15, color: color.darkBlue }}
            />
          </IconButton>
        </div>
      </Grid>
      <ModalForm sx={{width:{sm:'80%', md:'50%', lg:'30%', xs:'90%'}}} open={open} setOpen={setOpen}>
        <RegisterPage setLoading={setLoading} setOpen={setOpen} dispatch={dispatch}/>
      </ModalForm>
    </>
  );
};
