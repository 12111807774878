import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system';
import React from 'react';
import shortid from 'shortid';
import { color } from '../../../helpers/colors';

const StyledTitle = styled(TableCell)({
    color: color.white,
    fontFamily: 'Raleway'
})

const StyledCell = styled(TableCell)({
    fontFamily: 'Raleway'
});

export const TableResults = ({ columns, data }) => {
    return (

        <TableContainer component={Paper} sx={{ mt: 5 }}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead style={{ backgroundColor: color.darkBlue }}>
                    <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                        {columns?.map(column => (
                            <StyledTitle key={shortid.generate()}>{column.name}</StyledTitle>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row) => (
                        <TableRow
                            key={shortid.generate()}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            {columns?.map(column => (
                                <StyledCell key={shortid.generate()}>{column.render(row)}</StyledCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
