import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Menu, MenuItem, styled } from '@mui/material';
import { useRef, useState } from 'react';
import { color } from '../../../helpers/colors';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useDispatch } from 'react-redux';
import { types } from '../../../helpers/types';
import { routes } from '../../../helpers/routes';
import { useNavigate } from 'react-router-dom';
import shortid from 'shortid';

const MenuTitle = styled('span')({
    color: '#000000',
    fontFamily: 'Source Sans Pro',
    marginLeft: '2px',
    fontSize: '14px',
    fontWeight: 300
});

const menu = [
    {
        title: 'Mi Cuenta',
        icon: PersonOutlineOutlinedIcon,
        route:routes.profile
    },
    {
        title: 'Historial Pedidos',
        icon: ArticleOutlinedIcon,
        route: routes.ordersHistory
    },
    {
        title: 'Cambiar Contraseña',
        icon: LockClockOutlinedIcon,
        route:routes.changePassword
    },
    {
        title: 'Cerrar Sesión',
        icon: LogoutOutlinedIcon,
        logout: true
    },
]

export const UserMenu = ({ name }) => {
    const userMenu = useRef(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClicked = (route, logout) => {
        setAnchorEl(null);

        if (route) {
            navigate(route);
        } else if (logout) {
            dispatch({ type: types.logout });
        }
    };

    return (
        <div>
            <div
                onClick={handleClick}
                ref={userMenu}
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', position: 'relative', margin: '0px 5px 0px 5px' }}>

                <span style={{ color: color.black, fontFamily: 'Source Sans Pro', fontSize:13 }}>{name}</span>

                <KeyboardArrowDownIcon style={{ color: '#A4AFB7' }} />
            </div>
            <Menu
                id="simple-menu"
                style={{ zIndex: '8000' }}
                anchorEl={anchorEl}
                keepMounted
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorEl)}
                onClick={handleClose}
                onClose={handleClose}
                PaperProps={{
                    sx: { borderRadius: '11px' },
                    elevation: 8,
                }}
            >
                {
                    menu.map(({ icon: Icon, title, route, logout }) => (
                        <MenuItem key={shortid.generate()} onClick={() => handleMenuItemClicked(route, logout)}>
                            <Icon style={{ color: '#A4AFB7' }} />
                            <MenuTitle>
                                {title}
                            </MenuTitle>
                        </MenuItem>
                    ))
                }
            </Menu>
        </div >

    )
}
