import { Box, Grid } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import { color } from "../../../helpers/colors";
import { routes } from "../../../helpers/routes";
import iramac from '../../../img/brands/iramac.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const textStyle = {
  textDecoration: 'none',
  fontFamily: 'Montserrat',
  fontWeight: 400,
  color: color.darkBlue,
  fontSize: 15,
}
const Img = styled('img')({});

const Title = styled('h5')({
  color: color.darkBlue,
  fontFamily: 'Montserrat',
  fontWeight: 700,
  fontSize: 16,
  margin: 0,
  textTransform: 'uppercase'
});

const Ul = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

const StyledLink = styled(Link)(textStyle);

const A = styled('a')(textStyle);

export const Footer = () => {
  return (
    <footer style={{ marginTop: 15 }}>
      <Box
        component="div"
        sx={{
          p: 8,
          bgcolor: color.grey2
        }}>

        <Grid container justifyContent={'center'} >
          <Grid item xs={12} md={4}>
            <Img src={iramac}
              sx={{ maxWidth: { xs: '120px', md: '180px' } }} />
          </Grid>

          <Grid item container xs={12} md={4}>
            <Grid item xs={12}>
              <Title>
                Contáctenos
              </Title>
            </Grid>

            <Grid item container alignItems={'center'} xs={12}>
              <WhatsAppIcon style={{ color: color.darkBlue }} />
              <A href="https://wa.me/573160190733" target={'_blank'}>
                3160190733
              </A>
            </Grid>

            <Grid item container alignItems={'center'} xs={12}>
              <InstagramIcon style={{ color: color.darkBlue }} />
              <A href="https://www.instagram.com/iramacsas/" target={'_blank'}>
                @iramacsas
              </A>
            </Grid>

            <Grid item container alignItems={'center'} xs={12}>
              <MailOutlineIcon style={{ color: color.darkBlue }} />
              <A href="mailto: contactanos@iramac.com" target={'_blank'}>
                contactanos@iramac.com
              </A>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={4}>
            <Grid item xs={12}>
              <Title>
                Categorias
              </Title>
            </Grid>
            <Grid item xs={12}>
              <Ul>
                <li><StyledLink to={`${routes.products}?category=ELECTRICO`}>Electrico</StyledLink></li>
                <li><StyledLink to={`${routes.products}?category=MECANICO`}>Mecanico</StyledLink></li>
              </Ul>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box component="div"
        sx={{
          bgcolor: color.darkBlue,
          minHeight: 34,
          color: color.white,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        Copyright © 2021 Iramac todos los derechos reservados
      </Box>
    </footer>
  );
};
