import { Box, Button, Card, CardContent, Grid } from '@mui/material';
import { styled } from '@mui/system';
import React, { memo, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { color } from '../../../helpers/colors';
import { moneyLocale } from '../../../helpers/format'
import { useDispatch } from 'react-redux';
import { addProductToCart } from '../../../actions/cart';
import { ProductIMG } from '../../common/image/IMG';
import { useQuantitySelector } from '../../../hooks/useQuantitySelector';
import { ProductDetail } from './ProductDetail';

const CardProduct = styled(Card)({
    minHeight: '350px',
    maxWidth: '450px',
    boxShadow: '0px 8px 20px lightgray'
});

const ProductTitle = styled('h4')({
    fontFamily: 'Montserrat',
    fontWeight: 600,
    margin: 0,
    color: color.black,
    fontSize: '16px'
});

const ProductPrice = styled('span')({
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: '15px',
    color: color.lightGrey
});

const ProductDetails = styled('span')({
    fontFamily: 'Montserrat',
    fontWeight: 300,
    fontSize: '12px',
    color: color.lightGrey
});

const AddCartButton = styled(Button)({
    fontFamily: 'Montserrat',
    fontSize: '12px',
    textTransform: 'none',
    backgroundColor: color.red,
    color: color.white,
    fontWeight: 500,
    borderRadius: 0,
    '&:hover': {
        backgroundColor: color.darkRed
    }
});

export default memo(({ product, isLogued }) => {
    const dispatch = useDispatch();
    const [openDetail, setOpenDetail] = useState(false);

    const showProductDetail = async () => {
        setOpenDetail(true);
    }
    
    const { value, QuantitySelector } = useQuantitySelector();

    const handleAddToCart = () => dispatch(addProductToCart({ ...product, quantity: value }));

    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <CardProduct style={{ height: '100%' }}>
                    <CardContent style={{ padding: 0, height: '100%' }}>

                        <ProductIMG loading="lazy" src={product?.Imagenes[0]?.UrlImagen} alt="" style={{ height: '270px', width: '100%', objectFit: product?.Imagenes[0]?.UrlImagen ? 'cover' : 'contain', boxShadow: '0px 5px 6px lightgray' }} />

                        <Grid container alignItems={'flex-start'}>
                            <Grid container item xs={8} style={{ padding: 10 }} alignItems={'center'}>
                                <Grid item xs={12}>
                                    <ProductTitle>
                                        {product?.Nombre}
                                    </ProductTitle>
                                </Grid>

                                <Grid item xs={12}>
                                    <ProductPrice>
                                        {
                                            isLogued && (
                                                <>
                                                    {moneyLocale.format(product?.PrecioVenta)}
                                                </>
                                            )
                                        }
                                    </ProductPrice>
                                </Grid>

                                <Grid item xs={12} container alignItems={'center'} style={{ cursor: 'pointer' }}>
                                    <ProductDetails onClick={showProductDetail}>
                                        Más detalles
                                    </ProductDetails>
                                    <ArrowForwardIosIcon style={{ fontSize: '12px', color: color.lightGrey }} />
                                </Grid>
                            </Grid>
                            {
                                isLogued && (
                                    <Grid container item xs={4} style={{ padding: 12 }} justifyContent={'flex-end'} alignItems={'flex-end'}>
                                        <Box
                                            sx={{
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                            <QuantitySelector />
                                            <AddCartButton sx={{ mt: 1 }} onClick={() => handleAddToCart()} disableElevation variant="contained">Agregar</AddCartButton>
                                        </Box>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </CardContent>
                </CardProduct>
            </Grid>
            <ProductDetail isLogued={isLogued} setOpen={setOpenDetail} open={openDetail} product={product} />
        </>
    );
});
