import { Box } from '@mui/material'
import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import { color } from '../../../helpers/colors';

export const Category = ({ img, title, onClick = () => { }, selected }) => {
    return (
        <>
            <Box
                onClick={() => onClick(title)}
                sx={{
                    cursor: 'pointer',
                    width: 350,
                    height: 150,
                    position: 'relative',
                    overflow: 'hidden',
                    borderRadius: 8,
                }}>
                <img src={img} style={{
                    height: '100%',
                    position: 'absolute',
                    width: '100%',
                    objectFit: 'cover',
                    maxWidth: '100%'
                }} alt="" />
                <Box
                    sx={{
                        position: 'absolute',
                        bgcolor: 'black',
                        opacity: 0.5,
                        width: '100%',
                        height: '100%',
                    }} >
                </Box>
                <div
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <span
                        style={{
                            color: 'white',
                            fontFamily: 'Raleway',
                            fontSize: 30,
                            fontWeight: 'bold'
                        }}>
                        {title}
                    </span>
                </div>
                {
                    selected && (
                        <>
                            <Box
                                sx={{
                                    backgroundColor: 'black',
                                    width: '100%',
                                    opacity: 0.65,
                                    height: '100%',
                                    position: 'absolute',
                                }} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    position: 'absolute',
                                }} >
                                <CheckIcon
                                    sx={{
                                        color: color.white,
                                        fontSize: 65
                                    }} />
                            </Box>
                        </>
                    )
                }
            </Box>
        </>
    )
}
