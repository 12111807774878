import { Container, Grid, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { color } from '../../../helpers/colors'
import { ButtonControl } from '../../common/button/Button'
import { Input } from '../../common/input/Input'
import { TitleSection } from '../../common/title/TitleSection'
import { TableResults } from '../../common/table/TableResults'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ModalForm } from '../../common/modal/ModalForm';
import { styled } from '@mui/system';
import { usePost } from '../../../hooks/usePost'
import endpoint from '../../../api/endpoint'
import { useLoading } from '../../../hooks/useLoading'
import moment from 'moment'
import { getAsync } from '../../../api/apiService'
import { moneyLocale } from '../../../helpers/format'

const ProductDetailSubtitle = styled('h4')({
    color: color.darkBlue,
    fontFamily: 'Montserrat',
    fontWeight: 600,
    margin: 0
});

const ProductDetailSubtitleValue = styled('span')({
    color: color.lightGrey,
    fontFamily: 'Montserrat',
    fontWeight: 400
});

export const OrdersHistoryPage = () => {
    const methods = useForm();
    const [openDetail, setOpenDetail] = useState(false);
    const [productSelected, setProductSelected] = useState(null);
    const [filter, setFilter] = useState({});
    const setLoading = useLoading();
    const [detail, setDetail] = useState({});

    const showProductDetail = async (product) => {
        setOpenDetail(true);
        setProductSelected(product);

        setLoading(true);
        const response = await getAsync(endpoint.orderDetail.replace(':id', product.Codigo));
        setLoading(false);

        if (response.respuesta) {
            setDetail(response);
        }
    }

    const { data, loading } = usePost(endpoint.orderHistory, filter);

    const onSubmit = (data) => {
        setFilter({
            FechaInicio: moment(data.FechaInicio).format("DD/MM/YYYY"),
            FechaFin: moment(data.FechaFin).format("DD/MM/YYYY")
        });
    }

    useEffect(() => {
        setLoading(loading);
    }, [setLoading, loading])

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item container justifyContent={'center'} xs={12}>
                        <TitleSection>Historial de Pedidos</TitleSection>
                    </Grid>
                    <Grid item xs={12} >
                        <div style={{ borderBottom: `1px solid ${color.darkBlue}`, width: '54%', marginBottom: 40, marginTop: 15 }} />
                    </Grid>

                    <Container>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={5}>
                                <Input
                                    type="date"
                                    name="FechaInicio"
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Input
                                    type="date"
                                    name="FechaFin"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <ButtonControl
                                    type="submit"
                                    text={'Consultar'}
                                    style={{ textTransform: 'none' }}
                                    backgroundColor={color.red}
                                />
                            </Grid>

                            <TableResults
                                data={data?.datos}
                                columns={[
                                    { name: 'Codigo', render: row => row.Codigo },
                                    { name: 'Fecha', render: row => moment(row.Fecha).format("DD/MM/YYYY") },
                                    { name: 'Cantidad', render: row => row.CantidadProductos },
                                    { name: 'Valor', render: row => moneyLocale.format(row.ValorPedido) },
                                    {
                                        name: 'Acciones', render: (row) => (
                                            <IconButton onClick={() => showProductDetail(row)}>
                                                <VisibilityIcon style={{ color: color.black }} />
                                            </IconButton>
                                        )
                                    }
                                ]} />
                        </Grid>
                    </Container>
                </Grid>
                <ModalForm
                    divider
                    title={
                        <h3
                            style={{ fontFamily: 'Montserrat', fontWeight: 600, color: color.grey3 }}>
                            Detalle pedido
                        </h3>
                    }
                    setOpen={setOpenDetail}
                    open={openDetail} >
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={5}>
                            <ProductDetailSubtitle>Número de pedido</ProductDetailSubtitle>
                            <ProductDetailSubtitleValue>{productSelected?.Codigo}</ProductDetailSubtitleValue>
                        </Grid>

                        <Grid item xs={6} sm={5}>
                            <ProductDetailSubtitle>Fecha de pedido</ProductDetailSubtitle>
                            <ProductDetailSubtitleValue>{moment(productSelected?.Fecha).format("DD/MM/YYYY")}</ProductDetailSubtitleValue>
                        </Grid>

                        <Grid item xs={12}>
                            <TableResults
                                data={detail?.datos}
                                columns={[
                                    { name: 'Producto', render: row => row.Descripcion },
                                    { name: 'Cantidad', render: row => row.Cantidad },
                                    { name: 'Valor unidad', render: row => moneyLocale.format(row.Precio) },
                                    { name: 'Valor total', render: row => moneyLocale.format(row.Valor) },
                                ]} />
                        </Grid>

                        <Grid item xs={6} sm={5}>
                            <ProductDetailSubtitle>Valor total pedido: {moneyLocale.format(detail?.ValorTotal)}</ProductDetailSubtitle>
                        </Grid>
                    </Grid>
                </ModalForm>
            </form>
        </FormProvider>
    )
}
