import { Box, Container, Grid, IconButton, InputAdornment, InputBase, styled } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { TitleSection } from '../../common/title/TitleSection';
import { color } from '../../../helpers/colors';
import { ProductsList } from './ProductsList';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import mechanic from '../../../img/categories/mechanic.jpeg';
import electric from '../../../img/categories/electric.jpeg';
import { Category } from './Category';
import shortid from 'shortid';
import { useLoading } from '../../../hooks/useLoading';
import { postAsync } from '../../../api/apiService';
import endpoint from '../../../api/endpoint';
import { showSnack } from '../../../actions/ui';
import { usePagination } from '../../../hooks/usePagination';
import { Paging } from '../../common/paging/Paging';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

const SearchInput = styled(InputBase)({
  borderRadius: '28px',
  border: 'none',
  padding: '0px 15px 0px 15px',
  height: '50px',
  backgroundColor: color.lightGrey2
});

const categories = [
  {
    title: 'ELECTRICO',
    img: electric
  },
  {
    title: 'MECANICO',
    img: mechanic
  }
]

const perPage = 21;

export const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const { isLogued } = useSelector(state => state.auth);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const setLoading = useLoading();
  const [products, setProducts] = useState([]);
  const dispatch = useDispatch();

  const methods = useForm();


  const handleGetProducts = useCallback(async (category, description = '') => {
    setLoading(true);
    const response = await postAsync(endpoint.products, {
      Categoria: category,
      Descripcion: description
    });
    setLoading(false);

    if (response.respuesta) {
      setProducts(response.datos);
      return;
    }

    dispatch(showSnack(response.mensaje, 'error'));
  },
    [dispatch, setLoading]
  )

  const handleCategorySelected = useCallback(
    async (category) => {
      setSelectedCategory(category);
      await handleGetProducts(category);
    },
    [handleGetProducts],
  )

  useEffect(() => {
    const category = searchParams.get('category')
    if (category) {
      setSelectedCategory(category);
      handleCategorySelected(category);
    }
  }, [searchParams, setSelectedCategory, handleCategorySelected])



  const handleOnFilter = async (data) => {
    await handleGetProducts(selectedCategory, data.Descripcion);
  }

  const { paginatedData, totalPages, page, changePage } = usePagination(products, perPage);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleOnFilter)}>
          <Grid container>
            {
              selectedCategory && (
                <Grid item container justifyContent='center' style={{ marginTop: 40 }} xs={12}>
                  <SearchInput
                    {...methods.register("Descripcion")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton type="submit">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{ width: { xs: '80%', lg: '40%' } }}></SearchInput>
                </Grid>
              )
            }

            <Grid item container justifyContent='center' xs={12}>
              <TitleSection>Productos</TitleSection>
            </Grid>

            <Grid item xs={12} >
              <div style={{ borderBottom: `1px solid ${color.darkBlue}`, width: '54%', marginBottom: 40, marginTop: 15 }} />
            </Grid>

            <Box
              sx={{
                width: '100%',
                gap: 5,
                mb: 4,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center'
              }}>
              {
                categories.map(category => (
                  <Category
                    selected={selectedCategory === category.title}
                    onClick={handleCategorySelected}
                    key={shortid.generate()}
                    {...category} />
                ))
              }
            </Box>

            <Container>
              <Paging style={{ marginBottom: 20 }} changePage={changePage} page={page} totalPages={totalPages} />
              <ProductsList isLogued={isLogued} products={paginatedData} />
              <Paging style={{ marginTop: 20 }} changePage={changePage} page={page} totalPages={totalPages} />
            </Container>
          </Grid >
        </form>
      </FormProvider>
    </>
  );
};
