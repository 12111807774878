import { types } from "../helpers/types";
import { Buffer } from 'buffer';
import axios from "axios";

const initAuthReducer = {
    isLogued: false,
    Token: '',
    CodCliente: '',
    Correo: '',
    Id: '',
    Nombre: ''
};

export const initAuthStorage = () => {
    const user = localStorage.getItem('userIramac');

    if (!user) {
        return initAuthReducer;
    }

    const userData = JSON.parse(Buffer.from(user, 'base64'));
    axios.defaults.headers['Autorizacion'] = userData?.Token;


    return userData || initAuthReducer;
}


export const authReducer = (state = initAuthStorage(), action) => {
    switch (action.type) {
        case types.login:
            return {
                isLogued: true,
                ...action.payload
            }

        case types.logout:
            return {
                isLogued: false,
                ...initAuthReducer
            }

        default:
            return state;
    }
}
