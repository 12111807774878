export const routes = {
    home: '/',
    login: '/login',
    products: '/products',
    aboutUs: '/aboutUs',
    contactUs: '/contactUs',
    ordersHistory: '/ordersHistory',
    shoppingCart: '/shoppingCart',
    changePassword: '/changePassword',
    profile: '/profile',
};