import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";

const style = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 6,
  borderRadius: 20,
  outline: "none",
  position: "absolute",
  overflowY: "auto",
  top: "50%",
  maxWidth: { xs: "90%", md: "80%" },
  maxHeight: "calc(100% - 140px)",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const ModalForm = ({
  children,
  onClose = () => { },
  title,
  divider,
  sx,
  open,
  setOpen = () => { },
  ...rest
}) => {
  const onCloseModal = (_, reason) => {
    if (reason === "backdropClick") return;

    onClose();
    setOpen(false);
  };

  return (
    <Modal
      style={{
        border: "none",
        padding: 0,
        backdropFilter: "blur(8px)",
      }}
      {...rest}
      open={open}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, ...sx }}>
        <div
          style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            {title}
          </div>
          <div style={{ display: "flex", width: '100%', justifyContent: "flex-end" }}>
            <span
              onClick={() => setOpen(false)}
              style={{
                fontFamily: "Montserrat",
                fontWeight: 800,
                fontSize: "28px",
                cursor: "pointer",
              }}
            >
              X
            </span>
          </div>
        </div>
        {
          divider && (
            <Box component='div' sx={{ border: '0.6px solid #5D5D5D', ml: -6, mr: -6, mb: 2 }} />
          )
        }
        <div>{children}</div>
      </Box>
    </Modal >
  );
};
