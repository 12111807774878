import { useState, useEffect } from 'react'

export const usePagination = (data, perPage) => {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginatedData, setPaginatedData] = useState([]);

    const changePage = (newPage) => {
        if (newPage <= totalPages && newPage >= 1) {
            setPage(newPage);
        }
    }

    useEffect(() => {
        paginate(data, page, perPage, setPaginatedData);
    }, [data, setPaginatedData, perPage, page]);

    const paginate = (data, page, perPage, setPaginatedData) => {
        if (data === null || !Array.isArray(data))
            return;

        const paginatedData = data.slice((page - 1) * perPage, page * perPage);

        setPaginatedData(paginatedData);
    }

    useEffect(() => {
        if (data === null || !Array.isArray(data))
            return;

        setTotalPages(Math.ceil(data.length / perPage))
        setPage(1)
    }, [data, perPage, setPage,setTotalPages])


    return { paginatedData, totalPages, page, changePage };
}