import { types } from "../helpers/types";

export const login = (userData) => ({
    type: types.login,
    payload: {
        ...userData
    }
});

export const logout = () => ({
    type: types.logout
});