import { useState } from 'react';
import { blue } from '@mui/material/colors';

export const ReadMore = ({ children, textStyle, linkStyle }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <p style={textStyle}>
            {isReadMore ? text?.slice(0, 150) : text}
            {
                (text?.length > 150) && (
                    <span onClick={toggleReadMore} style={{ ...linkStyle, cursor: 'pointer', color: blue[700], fontWeight: '500' }}>
                        {isReadMore ? " ...Ver más" : " Ver menos"}
                    </span>
                )
            }
        </p>
    );
};