import { styled } from "@mui/material"
import { color } from "../../../helpers/colors";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { moneyLocale } from "../../../helpers/format";
import { removeProductCart, updateQuantity } from "../../../actions/cart";
import { ProductIMG } from "../../common/image/IMG";

var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const StyledCell = styled(TableCell)({
    borderBottom: '1px solid #707070',
    fontFamily: 'Montserrat',
    color: color.lightGrey,
    fontSize: '15px',
    fontWeight: 400
});

const TitleCell = styled(TableCell)({
    borderBottom: '1px solid #707070',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '20px',
    color: color.darkBlue
});

const ProductCell = styled(TableCell)({
    borderBottom: '1px solid #707070',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '17px',
    color: color.black
});


function priceRow(qty, unit) {
    return qty * unit;
}

export const ShoppingCartTable = ({ cart, dispatch }) => {

    const getTotal = () => cart.map(({ PrecioVenta, quantity }) => PrecioVenta * quantity).reduce((sum, i) => sum + i, 0);

    const handleDelete = (id) => dispatch(removeProductCart(id));

    const handleChangeQuantity = (e, id) => {
        dispatch(updateQuantity({
            Id: id,
            quantity: e.target.value
        }))
    }

    return (
        <TableContainer
            component={Paper}
            sx={{ border: '1px solid #707070', padding: 5 }}
            elevation={0}>
            <Table sx={{ minWidth: '100%' }} aria-label="spanning table" >
                <TableHead>
                    <TableRow>
                        <TitleCell align="center">Producto</TitleCell>
                        <TitleCell align="center">Valor unidad</TitleCell>
                        <TitleCell align="center">Cantidad</TitleCell>
                        <TitleCell align="center">Subtotal</TitleCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cart.map((row) => (
                        <TableRow key={row.Id}>
                            <ProductCell>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <DeleteIcon style={{ color: color.red, width: '30px', cursor: 'pointer' }} onClick={() => handleDelete(row.Id)} />
                                    <ProductIMG src={row.Imagenes[0]?.UrlImagen} style={{ height: 100, width: 110, objectFit: 'cover', margin: '0px 35px 0px 25px', boxShadow: '0px 5px 6px lightgray' }} />
                                    {row.Nombre}
                                </div>
                            </ProductCell>
                            <StyledCell align="center">{moneyLocale.format(row.PrecioVenta)}</StyledCell>
                            <StyledCell align="center">
                                <input
                                    type="number"
                                    min={1}
                                    onChange={e => handleChangeQuantity(e, row.Id)}
                                    value={row.quantity}
                                    style={{
                                        width: '50px',
                                        height: '30px',
                                        textAlign: 'center',
                                        fontFamily: 'Montserrat',
                                    }} />
                            </StyledCell>
                            <StyledCell align="center">{moneyLocale.format(priceRow(row.quantity, row.PrecioVenta))}</StyledCell>
                        </TableRow>
                    ))}

                    <TableRow>
                        <StyledCell style={{ border: 'none' }} rowSpan={5} colSpan={2} />
                        <StyledCell align="right" style={{ border: 'none', fontWeight: 600, color: color.darkBlue }}>TOTAL</StyledCell>
                        <StyledCell align="center" style={{ border: 'none', fontWeight: 600 }}>{formatter.format(getTotal())}</StyledCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
