import { Button, Grid } from '@mui/material';
import { Box } from '@mui/material/node_modules/@mui/system';
import { styled } from '@mui/system';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { color } from '../../../helpers/colors';
import { TitleSection } from '../../common/title/TitleSection';
import "react-multi-carousel/lib/styles.css";
import { ReadMore } from '../../common/readMore/ReadMore';
import { Brands } from './Brands';
import banner1 from '../../../img/banner1.png';
import banner2 from '../../../img/banner2.png';
import banner3 from '../../../img/banner3.png';
import check from '../../../img/check.svg';
import money from '../../../img/money.svg';
import time from '../../../img/time.svg';
import { useFetch } from '../../../hooks/useFetch';
import endpoint from '../../../api/endpoint';
import { ProductIMG } from '../../common/image/IMG';
import { useDispatch, useSelector } from 'react-redux';
import { moneyLocale } from '../../../helpers/format';
import { addProductToCart } from '../../../actions/cart';

const Characteristic = styled('h3')({
  fontFamily: 'Montserrat',
  fontWeight: 200,
  color: color.darkBlue,
  textAlign: 'center'
});


const AddCartButton = styled(Button)({
  fontFamily: 'Montserrat',
  fontSize: '12px',
  textTransform: 'none',
  backgroundColor: color.red,
  color: color.white,
  fontWeight: 500,
  borderRadius: 0,
  '&:hover': {
    backgroundColor: color.darkRed
  }
});


export const HomePage = () => {
  const { data: product } = useFetch(endpoint.productRandom);
  const { isLogued } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  return (
    <>
      <Carousel
        duration={100}
        indicatorIconButtonProps={{
          style: {
            fontSize: 2,
            margin: 2,
            color: 'transparent',
            border: `1px solid ${color.darkRed}`
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            backgroundColor: color.darkRed
          }
        }}
      >
        <div>
          <img style={{ maxHeight: '100%', width: '100%' }} src={banner1} alt='' />
        </div>
        <div>
          <img style={{ maxHeight: '100%', width: '100%' }} src={banner2} alt='' />
        </div>
        <div>
          <img style={{ maxHeight: '100%', width: '100%' }} src={banner3} alt='' />
        </div>
      </Carousel>

      <Box sx={{ background: 'linear-gradient(to right, #F6F6F7, #FBFBFB);', padding: 8 }}>
        <Grid container justifyContent={'center'}>

          <Grid item xs={12} sm={4} justifyContent='center' container alignItems={'center'}>
            <img src={check} alt='icon' style={{ height: 100, width: 100 }}></img>
            <Characteristic>Excelente calidad</Characteristic>
          </Grid>

          <Grid item xs={12} sm={4} justifyContent='center' container alignItems={'center'}>
            <img src={money} alt='icon' style={{ height: 100, width: 100 }}></img>
            <Characteristic>Los mejores precios</Characteristic>
          </Grid>

          <Grid item xs={12} sm={4} justifyContent='center' container alignItems={'center'}>
            <img src={time} alt='icon' style={{ height: 100, width: 100 }}></img>
            <Characteristic>Rapidez en la entrega</Characteristic>
          </Grid>

        </Grid>
      </Box>

      {product && (
        <Grid container>
          <Grid item container justifyContent='center' xs={12}>
            <TitleSection>Productos</TitleSection>
          </Grid>

          <Grid item xs={12} >
            <div style={{ borderBottom: `1px solid ${color.darkBlue}`, width: '54%', marginBottom: 40, marginTop: 15 }} />
          </Grid>

          <Grid item xs={12} container sx={{ pl: { xs: 1, md: 12 }, pr: { xs: 1, md: 12 } }} alignItems={'center'}>
            <Grid item xs={7} sm={9}>
              <ProductIMG style={{ maxHeight: 600, width: '100%' }} src={product?.datos?.Imagenes[0]?.UrlImagen} />
            </Grid>

            <Grid item xs={5} sm={3}>
              <Box component="div" sx={{ p: { xs: '0px 10px 0px 15px', md: '20px 30px 20px 50px' } }}
                style={{ backgroundColor: color.darkRed, color: color.white, textAlign: 'center', marginLeft: '-30px' }}>
                <span
                  style={{ fontFamily: 'Montserrat', fontWeight: 300, fontSize: 18 }}>
                  {product?.datos?.Nombre}
                </span>
              </Box>

              <div style={{ margin: '30px 0px 0px 20px', fontFamily: 'Montserrat', fontWeight: 200 }}>
                <ReadMore>
                  {product?.datos?.DescripcionLarga}
                </ReadMore>
              </div>

              {
                isLogued && (
                  <Grid container>

                    <Grid item xs={12}>
                      <span style={{
                        color: color.darkBlue,
                        fontSize: 20,
                        fontWeight: 'bold'
                      }}>
                        {moneyLocale.format(product?.datos?.PrecioVenta)}
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <AddCartButton onClick={() => dispatch(addProductToCart({ ...product.datos, quantity: 1 }))} disableElevation variant="contained">Agregar</AddCartButton>
                    </Grid>
                  </Grid>
                )
              }
            </Grid>

          </Grid>
        </Grid>
      )}

      <Grid container sx={{ mt: 5 }}>
        <Brands />
      </Grid>
    </>
  )
};
