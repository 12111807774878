import { Container, Grid } from '@mui/material';
import React from 'react';
import { color } from '../../../helpers/colors';
import { TitleSection } from '../../common/title/TitleSection';
import { MaterialInput } from '../../common/input/MaterialInput';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonControl } from '../../common/button/Button';
import { Box } from '@mui/system';
import { useLoading } from '../../../hooks/useLoading';
import { postAsync } from '../../../api/apiService';
import endpoints from '../../../api/endpoint';
import { useDispatch } from 'react-redux';
import { showSnack } from '../../../actions/ui';

export const ContactUsPage = () => {
    const methods = useForm();
    const { formState: { errors } } = methods;
    const setLoading = useLoading();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        data = {
            ...data,
            Mensaje : data.Mensaje + ` Telefono: ${data.phone}`,
            Asunto: 'Contactanos'
        }

        setLoading(true);
        const response = await postAsync(endpoints.contactUs, data);
        setLoading(false);

        if (response.respuesta)
            methods.reset();

        dispatch(showSnack(response.mensaje, response.respuesta ? 'success' : 'error'));
    }

    return (
        <Grid container>
            <Grid item container justifyContent='center' style={{ marginTop: 40 }} xs={12}>
                <TitleSection>Contáctenos</TitleSection>
            </Grid>

            <Grid item xs={12} >
                <div style={{ borderBottom: `1px solid ${color.darkBlue}`, width: '54%', marginBottom: 40, marginTop: 15 }} />
            </Grid>

            <Container sx={{ width: { xs: '100%', md: '70%', lg: '30%' } }}>
                <FormProvider {...methods}>
                    <Box component='form' onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid container spacing={2} justifyContent={'center'} >
                            <Grid item xs={12}>
                                <MaterialInput
                                    name="Nombre"
                                    required
                                    error={errors?.name}
                                    label="Nombre" />
                            </Grid>
                            <Grid item xs={12}>
                                <MaterialInput
                                    name="Email"
                                    type="email"
                                    error={errors?.email}
                                    required
                                    label="Email" />
                            </Grid>
                            <Grid item xs={12}>
                                <MaterialInput
                                type="tel"
                                    name="phone"
                                    label="Télefono" />
                            </Grid>
                            <Grid item xs={12}>
                                <MaterialInput
                                    multiline
                                    name="Mensaje"
                                    rows={4}
                                    label="Mensaje" />
                            </Grid>

                            <Grid item xs={12}>
                                <ButtonControl type="submit" text={'Enviar'} />
                            </Grid>
                        </Grid>
                    </Box>
                </FormProvider>
            </Container>
        </Grid>
    );
};
