import { styled } from '@mui/system';
import React from 'react';
import { color } from '../../../helpers/colors';

const H3 = styled('h3')({
    fontFamily: 'Montserrat',
    fontWeight: 700,
    color: color.darkBlue,
    margin: 0,
    marginTop: 10,
    fontSize: '30px'
});

export const TitleSection = ({ children, ...rest }) => {
    return <H3 {...rest}>{children}</H3>
};
