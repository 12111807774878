import { Container, Grid } from "@mui/material"
import { color } from "../../../helpers/colors";
import { TitleSection } from "../../common/title/TitleSection";
import { ShoppingCartTable } from "./ShoppingCartTable";
import { ButtonControl } from "../../common/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../../actions/cart";
import { useLoading } from "../../../hooks/useLoading";
import { postAsync } from "../../../api/apiService";
import endpoint from "../../../api/endpoint";
import { showSnack } from "../../../actions/ui";



export const ShoppingCart = () => {
    const { cart } = useSelector(state => state);
    const dispatch = useDispatch();
    const setLoading = useLoading();

    const handleCreateOrder = async () => {
        const orderDetail = {
            DetallePedido: cart.map(product => ({
                IdProducto: product.Id,
                Cantidad: product.quantity,
                Precio: product.PrecioVenta,
                Cambios: '0'
            }))
        }

        setLoading(true);
        const response = await postAsync(endpoint.createOrder, orderDetail);
        setLoading(false);

        if (response.respuesta) {
            dispatch(clearCart());
        }

        dispatch(showSnack(response.mensaje, response.respuesta ? 'success' : 'error'))
    }

    return (
        <Grid container>
            <Grid item container justifyContent={'center'} xs={12}>
                <TitleSection>Carrito de compras</TitleSection>
            </Grid>
            <Grid item xs={12} >
                <div style={{ borderBottom: `1px solid ${color.darkBlue}`, width: '54%', marginBottom: 40, marginTop: 15 }} />
            </Grid>

            <Container>
                <ShoppingCartTable cart={cart} dispatch={dispatch} />

                {
                    cart?.length > 0 && (
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={6}>
                                <ButtonControl
                                    backgroundColor={color.red}
                                    text={"Vaciar Carrito"}
                                    onClick={() => dispatch(clearCart())}
                                    style={{ marginBottom: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ButtonControl
                                    onClick={handleCreateOrder}
                                    text={"Realizar pedido"}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            </Container>

        </Grid>
    );
}
