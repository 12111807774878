import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import { routes } from '../helpers/routes';
import { NavBar } from '../components/common/navbar/NavBar';

export const PublicRoute = ({ isLogued, restricted, navBar = true, component: Component }) => {
  return (
    (isLogued && restricted) ?
      <Navigate to={routes.home} />
      :
      <div>
        {
          navBar && (
            <NavBar />
          )
        }

        <main>

          <Component />
        </main>
      </div>
  )
};

PublicRoute.propTypes = {
  isLogued: PropTypes.bool,
  component: PropTypes.func.isRequired
}
