import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PublicRoute } from './PublicRoute';
import { routes } from '../helpers/routes';
import { LoginPage } from '../components/pages/login/LoginPage';
import { HomePage } from '../components/pages/home/HomePage';
import { useDispatch, useSelector } from 'react-redux';
import { ProductsPage } from '../components/pages/products/ProductsPage';
import { AboutUsPage } from '../components/pages/aboutUs/AboutUsPage';
import { ContactUsPage } from '../components/pages/contactUs/ContactUsPage';
import { Footer } from '../components/common/footer/Footer';
import { Buffer } from 'buffer';
import { PrivateRouter } from './PrivateRoute';
import { OrdersHistoryPage } from '../components/pages/ordersHistory/OrdersHistoryPage';
import { ShoppingCart } from '../components/pages/shoppingCart/ShoppingCartPage';
import { Alert, Snackbar } from '@mui/material';
import { FullPageSpinner } from '../components/common/loader/FullPageSpinner';
import { hideSnack } from '../actions/ui';
import { ChangePasswordPage } from '../components/pages/changePassword/ChangePasswordPage';
import axios from 'axios';
import { ProfilePage } from '../components/pages/profile/ProfilePage';

export const AppRouter = () => {
    const { ui: { loading, snackbar }, auth, cart } = useSelector(state => state);
    const { isLogued, Token } = auth;
    const dispatch = useDispatch();

    const handleCloseSnack = () => {
        dispatch(hideSnack());
    }

    useEffect(() => {
        if (isLogued) {
            localStorage.setItem('userIramac', Buffer.from(JSON.stringify(auth)).toString('base64'));
        } else {
            localStorage.removeItem('userIramac');
        }

        if (Token) {
            axios.defaults.headers['Autorizacion'] = Token;
        }
    }, [isLogued, auth, Token])

    useEffect(() => {
        localStorage.setItem('cartIramac', Buffer.from(JSON.stringify(cart)).toString('base64'));
    }, [cart])

    return (
        <>
            <Snackbar open={snackbar?.open} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity={snackbar?.severity}>
                    {snackbar?.message}
                </Alert>
            </Snackbar>
            {
                loading && (
                    <FullPageSpinner />
                )
            }
            <BrowserRouter>
                <Routes>

                    <Route
                        path={routes.home}
                        element={
                            <PublicRoute
                                component={HomePage} />
                        } />

                    <Route
                        path={routes.login}
                        element={
                            <PublicRoute
                                restricted
                                navBar={false}
                                isLogued={isLogued}
                                component={LoginPage} />
                        } />

                    <Route
                        path={routes.products}
                        element={
                            <PublicRoute
                                component={ProductsPage} />
                        } />

                    <Route
                        path={routes.aboutUs}
                        element={
                            <PublicRoute
                                component={AboutUsPage} />
                        } />

                    <Route
                        path={routes.contactUs}
                        element={
                            <PublicRoute
                                component={ContactUsPage} />
                        } />

                    <Route
                        path={routes.ordersHistory}
                        element={
                            <PrivateRouter
                                isLogued={isLogued}
                                component={OrdersHistoryPage} />
                        } />

                    <Route
                        path={routes.profile}
                        element={
                            <PrivateRouter
                                isLogued={isLogued}
                                component={ProfilePage} />
                        } />

                    <Route
                        path={routes.changePassword}
                        element={
                            <PrivateRouter
                                isLogued={isLogued}
                                component={ChangePasswordPage} />
                        } />

                    <Route
                        path={routes.shoppingCart}
                        element={
                            <PrivateRouter
                                isLogued={isLogued}
                                component={ShoppingCart} />
                        } />

                    <Route
                        path={'*'}
                        element={
                            <Navigate to={routes.home} />
                        } />


                </Routes>
                <Footer />
            </BrowserRouter>
        </>
    );
};
