export const types={
    login: '[AUTH] login',
    logout: '[AUTH] logout',

    startLoading: '[UI] Start Loading',
    stopLoading: '[UI] Stop Loading',
    showSnack: '[UI] Show snack',
    hideSnack: '[UI] Hide snack',

    addProduct: '[CART] Add product',
    removeProduct: '[CART] Remove product',
    updateCart: '[CART] Update cart',
    clearCart: '[CART] Clear cart',
    updateQuantity : '[CART] Update quantity'
}