import { types } from "../helpers/types";
import { showSnack } from "./ui";

export const addProductToCart = (product) => (dispatch) => {
    dispatch(showSnack('Producto agregado al carrito', 'success'));
    dispatch({
        type: types.addProduct,
        payload: product
    });
};

export const removeProductCart = (id) => ({
    type: types.removeProduct,
    payload: {
        Id: id
    }
});

export const updateCart = (products) => ({
    type: types.updateCart,
    payload: products
});

export const clearCart = () => ({
    type: types.clearCart
});

export const updateQuantity = (data) => ({
    type: types.updateQuantity,
    payload: data
});