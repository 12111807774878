import { AppBar, Avatar, Box, Button, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, styled, SwipeableDrawer, Toolbar, useMediaQuery } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { routes } from '../../../helpers/routes';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { NavLink, useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import { grey } from "@mui/material/colors";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import './navBar.css';
import { color } from '../../../helpers/colors';
import { useTheme } from "@mui/system";
import { useState } from "react";
import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import InfoIcon from '@mui/icons-material/Info';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { useSelector } from 'react-redux';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { CustomNavLink } from '../CustomNavLink';
import { UserMenu } from "./UserMenu";
import iramac from '../../../img/brands/iramac.png'

const StyledDiv = styled('div')({
    minHeight: 34,
    backgroundColor: color.darkBlue,
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'Raleway',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center'
});

const StyledUl = styled('ul')({
    display: 'flex',
    width: '70%',
    justifyContent: 'space-evenly'
});

const StyledLi = styled('li')({
    textDecoration: 'none',
    height: '100%',
    position: 'relative',
    listStyle: 'none'
});

const StyledButton = styled(Button)({
    backgroundColor: color.darkBlue,
    marginBottom: 10,
    textTransform: 'none',
    width: 170,
    padding: 13,
    borderRadius: '28px',
    ':hover': {
        backgroundColor: color.darkBlue
    }
});

const StyledNavLink = styled(NavLink)({
    color: color.black,
    textDecoration: 'none'
});

const StyledCustomNavLink = styled(CustomNavLink)({
    color: color.black,
    textDecoration: 'none'
});

const Div = styled('div')({});

const Img = styled('img')({});

const menu = [
    {
        title: 'Inicio',
        route: routes.home,
        Icon: HomeIcon
    },
    {
        title: 'Productos',
        route: routes.products,
        Icon: InventoryIcon
    },
    {
        title: 'Nosotros',
        route: routes.aboutUs,
        Icon: InfoIcon
    },
    {
        title: 'Contáctenos',
        route: routes.contactUs,
        Icon: ContactPageIcon
    },
]

export const NavBar = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down('md'));

    const { isLogued, Nombre } = useSelector(state => state.auth);

    const [open, setOpen] = useState(false);

    return (
        <>
            <StyledDiv
                sx={{ fontSize: { sx: 15, sm: 16 }, textAlign: 'center' }}>
                <div style={{ display: 'flex' }}>
                    <span style={{ color: color.white, paddingRight: 30 }}>¿Necesitas asesoría? comunícate con nosotros</span>
                </div>

                <div style={{ height: '100%', paddingRight: 20, alignSelf: 'center', display: 'flex', cursor: 'pointer' }} onClick={() =>
                    window.open('https://wa.me/573160190733')
                }>
                    <WhatsAppIcon style={{ color: color.white, margin: 'auto', textAlign: 'center' }} />
                    <span style={{ color: color.white }}>3160190733</span>
                </div>

                <div style={{ height: '100%', paddingRight: 20, display: 'flex', cursor: 'pointer' }} onClick={() =>
                    window.open('mailto: contactanos@iramac.com')
                }>
                    <MailOutlineIcon style={{ color: color.white, margin: 'auto' }} />
                    <span style={{ color: color.white }}>contactanos@iramac.com</span>
                </div>

                <div style={{ height: '100%', display: 'flex', cursor: 'pointer' }} onClick={() =>
                    window.open('https://www.instagram.com/iramacsas/')
                }>
                    <InstagramIcon style={{ color: color.white }} />
                    <span style={{ color: color.white }}>@iramacsas</span>
                </div>
            </StyledDiv>
            <nav>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar
                        position="static"
                        elevation={0}>
                        <Toolbar
                            sx={{ bgcolor: color.white2, height: { xs: '90px', md: '110px' } }}>
                            <Div
                                sx={{ alignItems: { sx: 'center', md: 'flex-end' }, justifyContent: 'space-around' }}
                                style={{ display: 'flex', width: '100%', flexWrap: 'nowrap' }}>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    style={{ color: color.darkBlue }}
                                    aria-label="menu"
                                    onClick={() => setOpen(true)}
                                    sx={{ display: { xs: '', md: 'none' } }}>
                                    <MenuIcon />
                                </IconButton>
                                <Img src={iramac}
                                    sx={{ maxWidth: { xs: '120px', md: '160px' } }} />

                                {
                                    !hidden && (
                                        <>
                                            <StyledUl sx={{ display: { xs: 'none', md: 'flex' } }}>
                                                {
                                                    menu.map((item, index) => (
                                                        <StyledLi key={index}>
                                                            <StyledCustomNavLink
                                                                activeClassName={'activated'}
                                                                to={item.route}>
                                                                {item.title}
                                                            </StyledCustomNavLink>
                                                        </StyledLi>
                                                    ))
                                                }
                                            </StyledUl>

                                            <div
                                                style={{
                                                    minHeight: '110px',
                                                    marginRight: 20,
                                                    borderColor: grey[300],
                                                    borderLeft: '1px solid black',
                                                }}>
                                            </div>

                                            <WhatsAppIcon onClick={() =>
                                                window.open('https://wa.me/573160190733')
                                            } style={{ color: color.darkBlue, fontSize: 40, marginRight: 20, marginBottom: 10, cursor: 'pointer' }} />
                                            <InstagramIcon onClick={() =>
                                                window.open('https://www.instagram.com/iramacsas/')
                                            } style={{ color: color.darkBlue, fontSize: 40, marginRight: 20, marginBottom: 10, cursor: 'pointer' }} />

                                        </>
                                    )
                                }

                                {
                                    (!hidden && !isLogued) && (
                                        <StyledButton disableElevation variant="contained" onClick={() => navigate(routes.login)}>
                                            Portal Cliente
                                        </StyledButton>
                                    )
                                }

                                {
                                    isLogued && (
                                        <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
                                            <ShoppingCartIcon
                                                style={{
                                                    color: color.red,
                                                    fontSize: '35px',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => navigate(routes.shoppingCart)}
                                            />
                                            <UserMenu name={Nombre} />
                                            <Avatar>{Nombre?.split(' ').map(x => x.charAt(0)).join('')}</Avatar>
                                        </div>
                                    )
                                }


                            </Div>

                        </Toolbar>
                    </AppBar>
                </Box>
            </nav>

            <SwipeableDrawer
                anchor={'top'}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(false)}>
                <Box
                    sx={{ width: 'auto', bgcolor: color.white2 }}
                    role="presentation"
                    onClick={() => setOpen(false)}
                    onKeyDown={() => setOpen(false)}>
                    <List>
                        {menu.map(({ title, Icon, route }) => (
                            <StyledNavLink
                                key={title}
                                style={({ isActive }) => ({ color: isActive ? color.darkBlue : color.grey })}
                                to={route}>
                                <ListItem button key={title}>
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={title} />
                                </ListItem>
                            </StyledNavLink>
                        ))}
                    </List>
                    <Divider />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <StyledButton disableElevation variant="contained" onClick={() => navigate(routes.login)}>
                            Portal Cliente
                        </StyledButton>
                    </div>

                </Box>
            </SwipeableDrawer>
        </>
    );
};
