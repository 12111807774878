import {
  Autocomplete,
  CircularProgress,
  styled,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { color } from "../../../helpers/colors";

const StyledTextField = styled(TextField)({
  width: "100%",
  minWidth: "100%",
  "& label.Mui-focused": {
    color: color.darkBlue,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: color.grey,
    },
    "&.Mui-focused fieldset": {
      borderColor: color.darkBlue,
    },
  },
});

export const Select = ({
  data,
  validations,
  name,
  label,
  loading,
  ...rest
}) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <Controller
        rules={validations}
        control={control}
        name={name}
        render={({ field: { onChange, value = null, onBlur, onFocus } }) => (
          <>
            <Autocomplete
              {...register(name, validations)}
              value={value}
              {...rest}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={(e, v) => onChange(v?.value)}
              isOptionEqualToValue={(option, value) => option.value === value}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  error={errors[name] ? true : false}
                  label={label}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading && (
                          <CircularProgress color="inherit" size={20} />
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </>
        )}
      />
    </div>
  );
};
