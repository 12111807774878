import { Container, Grid } from '@mui/material'
import { styled } from '@mui/material/node_modules/@mui/system'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import endpoint from '../../../api/endpoint'
import { color } from '../../../helpers/colors'
import { useFetch } from '../../../hooks/useFetch'
import { useLoading } from '../../../hooks/useLoading'
import { TitleSection } from '../../common/title/TitleSection'

const Title = styled('span')({
    fontWeight: 'bold',
    color: color.darkBlue,
    fontSize: 19
});

const Body = styled('span')({
    fontSize: 16

});

export const ProfilePage = () => {
    const { Id } = useSelector(state => state.auth);
    const setLoading = useLoading();

    const { data, loading } = useFetch(endpoint.profile.replace(':id', Id));

    useEffect(() => {
        setLoading(loading);
    }, [loading, setLoading])


    return (
        <Grid container>
            <Grid item container justifyContent='center' style={{ marginTop: 40 }} xs={12}>
                <TitleSection>Mi cuenta</TitleSection>
            </Grid>

            <Grid item xs={12} >
                <div style={{ borderBottom: `1px solid ${color.darkBlue}`, width: '54%', marginBottom: 40, marginTop: 15 }} />
            </Grid>

            <Container>
                <Grid item xs={12} container justifyContent={'center'}>
                    <Title>Nombre</Title>
                </Grid>
                <Grid item xs={12} container justifyContent={'center'}>
                    <Body>{data?.datos?.Nombre}</Body>
                </Grid>

                <Grid sx={{mt:2}} item xs={12} container justifyContent={'center'}>
                    <Title>Telefono</Title>
                </Grid>
                <Grid item xs={12} container justifyContent={'center'}>
                    <Body>{data?.datos?.Telefono}</Body>
                </Grid>

                <Grid sx={{mt:2}} item xs={12} container justifyContent={'center'}>
                    <Title>Correo</Title>
                </Grid>
                <Grid item xs={12} container justifyContent={'center'}>
                    <Body>{data?.datos?.Correo}</Body>
                </Grid>
            </Container>
        </Grid>
    )
}
