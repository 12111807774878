import { Container, Grid } from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { showSnack } from '../../../actions/ui'
import { putAsync } from '../../../api/apiService'
import endpoint from '../../../api/endpoint'
import { color } from '../../../helpers/colors'
import { useLoading } from '../../../hooks/useLoading'
import { ButtonControl } from '../../common/button/Button'
import { MaterialInput } from '../../common/input/MaterialInput'
import { TitleSection } from '../../common/title/TitleSection'

export const ChangePasswordPage = () => {
    const methods = useForm();
    const dispatch = useDispatch();
    const setLoading = useLoading();

    const { Id } = useSelector(state => state.auth);

    const onSubmit = async (data) => {
        if (data.Contrasena !== data.confirmarContrasena) {
            dispatch(showSnack('Las contraseñas deben coincidir', 'warning'));
            return;
        }

        setLoading(true);
        const response = await putAsync(endpoint.changePassword, {
            Id,
            Contrasena: data.Contrasena
        });
        setLoading(false);

        methods.reset();
        dispatch(showSnack(response.mensaje, response.respuesta ? 'success' : 'error'));
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item container justifyContent='center' style={{ marginTop: 40 }} xs={12}>
                        <TitleSection>Cambiar contraseña</TitleSection>
                    </Grid>

                    <Grid item xs={12} >
                        <div style={{ borderBottom: `1px solid ${color.darkBlue}`, width: '54%', marginBottom: 40, marginTop: 15 }} />
                    </Grid>

                    <Container>
                        <Grid container spacing={2} >

                            <Grid item xs={12} sx={{ ml: { xs: 0, sm: 10, md: 25 }, mr: { xs: 0, sm: 10, md: 25 } }}>
                                <MaterialInput
                                    name="Contrasena"
                                    type="password"
                                    label="Contraseña"
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ ml: { xs: 0, sm: 10, md: 25 }, mr: { xs: 0, sm: 10, md: 25 } }}>
                                <MaterialInput
                                    type="password"
                                    name="confirmarContrasena"
                                    label="Confirmar Contraseña"
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ ml: { xs: 0, sm: 10, md: 25 }, mr: { xs: 0, sm: 10, md: 25 } }}>
                                <ButtonControl
                                    text={'Actualizar'}
                                    type="submit" />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </form>

        </FormProvider>
    )
}
